
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.flexible-picture {
  transition: opacity 0.2s;

  &.before-load {
    opacity: 0;
  }
}

.flexible-picture__picture {
  @extend %border-radius-small;

  ::v-deep .picture__container {
    @extend %border-radius-small;

    height: 0;
    padding-bottom: 66.66%;
  }

  &.full {
    ::v-deep .picture__container {
      padding-bottom: 65%;
      border-radius: 0;
    }

    // Needed for Scrolltrigger:
    // ::v-deep img.picture__inner {
    //   top: 50%;
    //   bottom: initial;
    //   height: 130%;
    //   transform: translateY(-50%);
    // }
  }

  @include mq(l) {
    &.full {
      ::v-deep .picture__container {
        padding-bottom: 45%;
      }
    }
  }
}

.flexible-picture__link {
  @include get-all-space;

  z-index: 1;
}

.flexible-picture__link__label {
  @extend %visually-hidden;
}
